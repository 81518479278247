<template>
  <div class="opiniao">
    <van-sticky>
      <van-nav-bar title="Feedback" left-arrow @click-left="onClickLeft" @click-right="onClickRight" class="retreat">
        <template #right>
          History
        </template>
      </van-nav-bar>
    </van-sticky>
    <!--        <div class="tipo-de-problema ">Select your problem</div>-->
    <van-form @submit="onSubmit">
      <div style="margin: 16px;">
        <van-radio-group v-model="radio">
          <van-radio :value="item" v-for="item in feedbackTags" :key="item" :name="item">{{ item }}</van-radio>
        </van-radio-group>
        <van-field v-model="message" name="content" rows="10" :autosize="true" type="textarea" maxlength="200"
                   placeholder="Please write your feedback first ~" show-word-limit/>

        <div style="height:1rem;"></div>
        <van-button round block type="danger" native-type="submit" style="font-weight: bold; font-size: 16px">Submit
        </van-button>
      </div>
    </van-form>
  </div>
</template>
<script>
import {setfankui} from '@/api/Mue/index'

export default {
  name: '',
  components: {},
  data() {
    return {
      radio: null, //单选框的默认选项
      message: null, // 文本域
      fankuiMag: null, //需要漏油传的参
      feedbackTags: [],// 单选框的数据
    }
  },
  // 计算属性
  computed: {},
  watch: {},
  mounted() {
    this.getFanKui()
  },
  methods: {
    onClickLeft() {
      this.$router.push('/meu')
    },
    onClickRight() {
      this.$router.push({
        path: '/feedback/record',
        query: this.fankuiMag
      })
    },
    onSubmit(content) {
      const list = {
        type: 1,
        tags: this.radio,
        content: content.content
      }
      setfankui(list).then()
      this.$router.push('/meu')
    },
    // 获取反馈数据
    getFanKui() {
      const sys_config = JSON.parse(localStorage.getItem('sys_config'))
      const feedback_tags = sys_config.feedback_tags
      this.feedbackTags = feedback_tags.split(",")
      this.radio = this.feedbackTags[0]
    }
  }
}
</script>
<style lang="scss" scoped>
.opiniao {
  height: 100vh;
  background-color: var(--main-color);
}

/deep/ .van-nav-bar {
  font-family: PingFang SC;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: 0px;
  color: var(--title-color);
}

/deep/ .van-nav-bar .van-icon {
  opacity: 0.8;
  font-size: 18px;
  color: var(--title-color);
}

/deep/ .van-nav-bar__title {
  opacity: 1;
  font-weight: bold;
  font-size: 18px;
  color: var(--title-color);
}

/deep/ .van-nav-bar__right {
  font-size: 12px;
  color: var(--minor-text-color);
}

.tipo-de-problema {
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 16px;
  letter-spacing: 0px;
  opacity: 0.8;
  padding: 10px;
}

/deep/ .van-radio-group {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 20px;
  letter-spacing: 0px;
  color: #626262;
}

/deep/ .van-radio {
  padding-bottom: 10px;
}

/deep/ .van-field__control {
  background-color: var(--minor-color);
  border-radius: 8px;
  padding: 0.2rem;
  color: var(--title-color);
}

/deep/ .van-cell {
  padding: 0;
  background-color: var(--main-color);
}

/deep/ .van-cell::after {
  border: 0;
}

/deep/ .van-field__word-limit {
  color: var(--title-color);
}

/deep/ .van-radio__icon--checked .van-icon {
  color: var(--title-color);
  background-color: var(--minor-color);
  border-color: var(--title-color);
}

/deep/ .van-radio__label {
  color: var(--title-color);
}
</style>
