import request from '@/utils/requests'
import qs from 'qs'


// 排行榜数据
export const getHistory = data => {
  return request({
    url: '/user_history/get_list',
    method: 'post',
    data:qs.stringify(data)
  })
}
// 反馈数据接口
export const getfankui = data => {
  return request({
    url: '/public/get_sys_conf',
    method: 'post',
    data:qs.stringify(data)
  })
}

// 反馈数据接口
export const setfankui = data => {
  return request({
    url: '/public/feedback',
    method: 'post',
    data:qs.stringify(data)
  })
}
// 反馈信息列表
export const getfkInfo = data => {
  return request({
    url: '/user/feedback_list',
    method: 'post',
    data:qs.stringify(data)
  })
}

// 删除接口
export const remove = data => {
  return request({
    url: '/user_history/remove',
    method: 'post',
    data:qs.stringify(data)
  })
}